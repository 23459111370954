import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PageTitle from "../common/page-title";

const DocumentNavbar = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          documentLinks {
            to
            name
          }
          pageDocumentTitle
        }
      }
    }
  `);

  return (
    <div>
      <PageTitle title={data.site.siteMetadata.pageDocumentTitle} />
      <nav className="bt bb bg-white b--light-gray tc mw-100 center">
        {data.site.siteMetadata.documentLinks.map((navLink) => (
          <Link
            key={navLink.name}
            className="sans-serif ttu gray hover-black-90 f5 no-underline dn dib-l dib pa3 ph5-l"
            activeClassName="green"
            to={navLink.to}
          >
            {navLink.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default DocumentNavbar;
