import React from "react";

const PageTitle = ({ title }) => {
  return (
    <div className="pv3 flex items-center justify-center bg-washed-green">
      <h1 className="fw1 tc f2 display">{title}</h1>
    </div>
  );
};

export default PageTitle;
