import React from "react";
import { Link, navigate } from "gatsby";

const Pagination = (props) => {
  function changePage(e) {
    navigate(
      e.target.value
        ? `${props.paginationPath}/${e.target.value}`
        : `${props.paginationPath}/`
    );
  }

  return (
    <div className="mw9 center flex mt4 mb5 ph4-ns">
      {/* previousPageLink and nextPageLink were added by the plugin */}
      <div className="flex-auto">
        <select
          className="f4 sans-serif pa3 dark-gray"
          value={props.humanPageNumber === 1 ? `` : props.humanPageNumber}
          onChange={changePage}
        >
          {Array.from({ length: props.numberOfPages }, (_, i) => (
            <option value={`${i === 0 ? `` : i + 1}`} key={`page${i + 1}`}>
              {i + 1}
            </option>
          ))}
        </select>
        <span className="f4 sans-serif dark-gray pl3">/</span>
        <span className="f4 sans-serif dark-gray pl3">
          {props.numberOfPages}
        </span>
      </div>
      <div className="flex">
        {props.previousPagePath && (
          <Link
            className="f5 ttu sans-serif no-underline dark-gray bg-animate hover-bg-dark-gray hover-white inline-flex items-center pa3 ba b--dark-gray border-box mr4"
            to={props.previousPagePath}
          >
            <svg
              className="w1"
              data-icon="chevronLeft"
              viewBox="0 0 32 32"
              style={{ fill: "currentcolor" }}
            >
              <title>chevronLeft icon</title>
              <path d="M20 1 L24 5 L14 16 L24 27 L20 31 L6 16 z" />
            </svg>
            <span className="pl1">Sebelumnya</span>
          </Link>
        )}
        {props.nextPagePath && (
          <Link
            className="f5 ttu sans-serif no-underline dark-gray bg-animate hover-bg-dark-gray hover-white inline-flex items-center pa3 ba b--dark-gray border-box"
            to={props.nextPagePath}
          >
            <span className="pr1">Selanjutnya</span>
            <svg
              className="w1"
              data-icon="chevronRight"
              viewBox="0 0 32 32"
              style={{ fill: "currentcolor" }}
            >
              <title>chevronRight icon</title>
              <path d="M12 1 L26 16 L12 31 L8 27 L18 16 L8 5 z" />
            </svg>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Pagination;
