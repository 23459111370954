import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import Layout from "../components/common/layout";
import DocumentNavbar from "../components/document/document-navbar";
import ReportList from "../components/document/report-list";
import Pagination from "../components/common/pagination";

export const query = graphql`
  query ReportQuery($skip: Int!, $limit: Int!) {
    reports: allSanityReport(
      skip: $skip
      limit: $limit
      sort: { order: ASC, fields: uploadedFile___caption }
    ) {
      edges {
        node {
          id
          author
          uploadedFile {
            caption
            asset {
              url
              originalFilename
            }
          }
        }
      }
    }
  }
`;

const Report = (props) => {
  const { data } = props;

  const nodes = (data || {}).reports ? mapEdgesToNodes(data.reports) : [];

  return (
    <Layout>
      <DocumentNavbar />
      {nodes && <ReportList nodes={nodes} />}
      <Pagination
        humanPageNumber={props.pageContext.humanPageNumber}
        numberOfPages={props.pageContext.numberOfPages}
        previousPagePath={props.pageContext.previousPagePath}
        nextPagePath={props.pageContext.nextPagePath}
        paginationPath={props.pageContext.paginationPath}
      />
    </Layout>
  );
};

export default Report;
